// Used for selecting notes to see potential chords
export const SELECT_CHORD_NOTE = "SELECT_CHORD_NOTE";
export const DESELECT_CHORD_NOTE = "DESELECT_CHORD_NOTE";

// Used for selecting chords to see their notes
export const SELECT_CHORD_ROOT = "SELECT_CHORD_ROOT";
export const SELECT_CHORD_TYPE = "SELECT_CHORD_TYPE";

// Used for selecitng notes to see potential scales
export const SELECT_SCALE_NOTE = "SELECT_SCALE_NOTE";
export const DESELECT_SCALE_NOTE = "DESELECT_SCALE_NOTE";

// Used for selecting scales to see their notes
export const SELECT_SCALE_ROOT = "SELECT_SCALE_ROOT";
export const SELECT_SCALE_TYPE = "SELECT_SCALE_TYPE";

// Used to reset state
export const CLEAR_CHORD_SELECTION = "CLEAR_CHORD_SELECTION";
export const CLEAR_SCALE_SELECTION = "CLEAR_SCALE_SELECTION";